'use client';

import { Button, Typography } from '@little-worker/hestia';
import * as Sentry from '@sentry/nextjs';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import React, { ReactNode, useEffect } from 'react';

import ErrorImage from '@assets/images/error.png';

export default function Error({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  const mailToChunk = (chunks: ReactNode) => (
    <a className="underline" href="mailto:contact@littleworker.fr">
      {chunks}
    </a>
  );

  const t = useTranslations('500');

  return (
    <div className="grid grid-cols-4 max-w-[718px] lg:w-full lg:mx-auto">
      <div className="col-span-4 relative">
        <Image
          className="mx-auto rounded-t-rounded"
          src={ErrorImage}
          width={345}
          alt="illustration"
        />
        <Typography
          className="
          text-text-white whitespace-pre-line absolute bottom-[90px] left-1/2 -translate-x-1/2 font-playfair_display"
          variant="display"
          size="large"
        >
          500
        </Typography>
      </div>
      <Typography size="large" fontStyle="bold" className="col-span-4 mt-500 text-center">
        {t('content')}
      </Typography>
      <Button
        className="mt-200 col-span-4"
        iconName="ArrowLeft01"
        iconSide="left"
        href={document.referrer !== '' ? document.referrer : '/'}
        asLink
      >
        {t('cta')}
      </Button>
      <Typography className="mt-200 text-center col-span-4">
        {t.rich('contact_support', {
          mail: mailToChunk,
        })}
      </Typography>
    </div>
  );
}
